.theader-br {
  background-color: rgba(169, 172, 175, 0.267);
  color: #31363F;
  width: 10%;
}

.table-ssc-tbl1 {
  font-family: "Latam Sans Regular Italic";
  font-size: 14px;
  font-weight: lighter !important;
  width: 78%;
  color: #000000;
  padding: 0px;
  border-collapse: separate;
  overflow: visible;
  left: 32px;
  position: relative;
  border-spacing: 0px 3px;
  top: 51px;
}

.row-schedule-tbl1{
  position: relative;
  background-color: inherit;
  bottom: 85px;
  right: 35px;
  width: 11%;
  max-width: 150%;
}

.row-schedule-th-tbl1{
  background-color: #F6F6F8;
  border-radius: 9px;
  padding: 19px;
  position: static;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  bottom: -4px;
  display: table-cell;
  margin: 0px !important;
  max-width: 91.7% !important;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid #cccccc !important;

}

.row-schedule-container-tbl1 {

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 128px !important;

}

.row-schedule-box-tbl1{
  text-align: center;
  padding: 15px 0;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px !important;
  color: #000000;
  flex-grow: 0 !important;
  min-width: 100px !important;
  max-width: 30px !important;
  width: 45px !important;
  padding: 2px 0 !important;
  font-size: 12px !important;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.col1-br {
  width: 64px;
  position: relative;
  box-shadow: 0px 1px 3px #cccccc;
  border-radius: 4px;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  left: -35px;
  font-family: 'Latam Sans Bold';
  display: flex;
  justify-content: center;
  height: 24px;
  place-items: end;
  align-items: center;
  bottom: 421px;
}

.row-mando-cabina{
  display: grid  ;
  margin-top: -2px;
}

.mando-box-tbl1-00000300{
  padding: 11px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  justify-content: center;
  width: 108px;
  position: relative;
  bottom: 7px;
  margin-right: -2px;
  bottom: 77px;
  right: 34px;
}
.cabina-box-tbl1-00000300{
  padding: 11px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 107px;
  position: relative;
  margin-right: -2px;
  bottom: 118px;
  left: 104px;
}
.mando-box-tbl1-03000600{
  padding: 11px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 107px;
  position: relative;
  margin-right: -2px;
  bottom: 159px;
  left: 241px;
}
.cabina-box-tbl1-03000600{
  padding: 11px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 108px;
  position: relative;
  margin-right: -2px;
  bottom: 200px;
  left: 378px;
}
.mando-box-tbl1-06000900{
  padding: 11px;
    border-radius: 8px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    display: inline-block;
    font-weight: 900;
    font-family: "Latam Sans Bold";
    font-size: 14px;
    color: #000000;
    background-color: #F6F6F8;
    border: 1px solid #cccccc;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 107px;
    position: relative;
    margin-right: -2px;
    bottom: 241px;
    left: 516px;
}
.cabina-box-tbl1-06000900{
  padding: 11px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 107px;
  position: relative;
  margin-right: -2px;
  bottom: 282px;
  left: 653px;
}
.mando-box-tbl1-09001200{
  padding: 11px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 107px;
  position: relative;
  margin-right: -2px;
  bottom: 323px;
  left: 790px;
}
.cabina-box-tbl1-09001200{
  padding: 11px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 108px;
  position: relative;
  margin-right: -2px;
  bottom: 364px;
  left: 927px;
}

.bra-tbl1-0am {
  font-size: 14px !important;
  position: relative;
  right: 62px;
}

.bra-tbl1-3am {
  font-size: 14px !important;
  position: relative;
  right: 31px;
}

.bra-tbl1-6am {
  flex-grow: 12;
  font-size: 14px !important;
  position: relative;
  right: -20px;
}

.bra-tbl1-9am {
  flex-grow: 7;
  font-size: 14px !important;
  position: relative;
  left: 61px;
}

.doble-br {
  height: 25px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  padding: 0px;
  position: relative;
  bottom: 358px;
  right: 31px;
}

 .img-ato-casa{
  width: 15px;
    height: 15px;
    top: 2px;
    position: relative;
    left: 6px;
 }

.rojo-br {
  background-color: #ED1650;
  padding: 0px;
}

.amarillo-br {
  background-color: #FFC700;
  padding: 0px;
}


.input-br {
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
}

.main-br {
  width: 100%;
  display: grid;
}

.show-br {
  display: block;
}

.hidden-br {
  display: none;
}
.scheduler{
  background: rgba(169, 172, 175, 0.267);
  color: #31363F;
  height: 34px;
  border: 1px solid rgb(111, 113, 116) !important;
  width: 22%;

}
.col-br{
  width: 10%;
}

/*colores 00-03*/
.a-ato-mando-00000300 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: -2px;
  bottom: 1px;
  display: flex;

}
.a-casa-mando-00000300 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: 31px;
  bottom: 23px;
  display: flex;
}
.r-ato-mando-00000300 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;  
  box-sizing: border-box;
  position: relative;
  left: 66px;
  bottom: 45px;
  display: flex;
}
.r-casa-mando-00000300 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: 99px;
  bottom: 67px;
  display: flex;
}
.a-ato-cabina-00000300 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 88px;
  bottom: 1px;
  display: flex;
}
.a-casa-cabina-00000300 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 55px;
  bottom: 23px;
  display: flex;
}
.r-ato-cabina-00000300 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 20px;
  bottom: 45px;
  display: flex;

}
.r-casa-cabina-00000300 {
  border-radius: 5px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    width: 30px;
    height: 22px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    position: relative;
    right: -13px;
    bottom: 67px;
    display: flex;
}

/*colores 03-06*/
.a-ato-mando-03000600 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
     right: 2px;
    bottom: 1px;
  display: flex;
}
.a-casa-mando-03000600 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -31px;
  bottom: 23px;
  display: flex;
}
.r-ato-mando-03000600 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -67px;
  bottom: 45px;
  display: flex;
}
.r-casa-mando-03000600 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -99px;
  bottom: 67px;
  display: flex;
}
.a-ato-cabina-03000600 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 3px;
  bottom: 1px;
  display: flex;
}
.a-casa-cabina-03000600 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -30px;
  bottom: 23px;
  display: flex;
}
.r-ato-cabina-03000600 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -66px;
  bottom: 45px;
  display: flex;
}
.r-casa-cabina-03000600 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -98px;
  bottom: 67px;
  display: flex;
}

/*colores 06-09*/
.a-ato-mando-06000900 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 2px;
  bottom: 1px;
  display: flex;
}
.a-casa-mando-06000900 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -31px;
  bottom: 23px;
  display: flex;
}
.r-ato-mando-06000900 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -67px;
  bottom: 45px;
  display: flex;
}
.r-casa-mando-06000900 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -99px;
  bottom: 67px;
  display: flex;
}
.a-ato-cabina-06000900 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 3px;
  bottom: 1px;
  display: flex;
}
.a-casa-cabina-06000900 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -30px;
  bottom: 23px;
  display: flex;
}
.r-ato-cabina-06000900 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -66px;
  bottom: 45px;
  display: flex;
}
.r-casa-cabina-06000900 {
  border-radius: 5px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    width: 30px;
    height: 22px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    position: relative;
    right: -98px;
    bottom: 67px;
    display: flex;
}

/*colores 09-12*/
.a-ato-mando-09001200 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 2px;
  bottom: 1px;
  display: flex;
}
.a-casa-mando-09001200 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -31px;
  bottom: 23px;
  display: flex;
}
.r-ato-mando-09001200 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -67px;
  bottom: 45px;
  display: flex;
}
.r-casa-mando-09001200 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -99px;
  bottom: 67px;
  display: flex;
}
.a-ato-cabina-09001200 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 3px;
  bottom: 1px;
  display: flex;
}
.a-casa-cabina-09001200 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -30px;
  bottom: 23px;
  display: flex;
}
.r-ato-cabina-09001200 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -66px;
  bottom: 45px;
  display: flex;
}
.r-casa-cabina-09001200 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -98px;
  bottom: 67px;
  display: flex;
}

/*00-03 mando*/
.a-ato-mando{
  width: 30px !important;
  height: 27px !important;
  position: relative;
  right: 2px;
  top: -66px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.input-a-ato-mando{
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  background-color: #f6f6f8;
  font-weight: 600;
}
.a-casa-mando{
  width: 30px !important;
  height: 27px !important;
  position: relative;
  right: 3px;
  top: -66px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.input-a-casa-mando{
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  background-color: #f6f6f8;
  font-weight: 600;
}
.r-ato-mando{
  width: 30px !important;
  height: 27px !important;
  position: relative;
  right: -2px;
  top: -66px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.input-r-ato-mando{
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  background-color: #f6f6f8;
  font-weight: 600;
}
.r-casa-mando{
  width: 30px !important;
  height: 27px !important;
  position: relative;
  right: -1px;
  top: -66px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.input-r-casa-mando{
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  background-color: #f6f6f8;
  font-weight: 600;
}
/*00-03 cabina*/
.a-ato-cabina{
  width: 30px !important;
  height: 27px !important;
  position: relative;
  right: -6px;
  top: -66px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;

  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.input-a-ato-cabina{
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  background-color: #f6f6f8;
  font-weight: 600;
}
.a-casa-cabina{
  width: 30px !important;
  height: 27px !important;
  position: relative;
  right: -5px;
  top: -66px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.input-a-casa-cabina{
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  background-color: #f6f6f8;
  font-weight: 600;
}
.r-ato-cabina{
  width: 30px !important;
  height: 27px !important;
  position: relative;
  right: -10px;
  top: -66px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.input-r-ato-cabina{
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  background-color: #f6f6f8;
  font-weight: 600;
}
.r-casa-cabina{
  width: 32px !important;
  height: 27px !important;
  position: relative;
  right: -9px;
  top: -66px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.input-r-casa-cabina{
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  background-color: #f6f6f8;
  font-weight: 600;
}


/** TABLA 2**/


.table-ssc-tbl2 {
  font-family: "Latam Sans Regular Italic";
  font-size: 14px;
  font-weight: lighter !important;
  width: 78%;
  color: #000000;
  padding: 0px;
  border-collapse: separate;
  overflow: visible;
  left: 32px;
  position: relative;
  border-spacing: 0px 3px;
  bottom: 295px;
}

.row-schedule-tbl2{
  position: relative;
  background-color: inherit;
  bottom: 85px;
  right: 35px;
  width: 11%;
  max-width: 150%;
}

.row-schedule-th-tbl2{
  background-color: #F6F6F8;
  border-radius: 9px;
  padding: 19px;
  position: static;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  bottom: -4px;
  display: table-cell;
  margin: 0px !important;
  max-width: 91.7% !important;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid #cccccc !important;

}

.row-schedule-container-tbl2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 128px !important;

}

.row-schedule-box-tbl2{
  text-align: center;
  padding: 15px 0;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px !important;
  color: #000000;
  flex-grow: 0 !important;
  min-width: 100px !important;
  max-width: 30px !important;
  width: 45px !important;
  padding: 2px 0 !important;
  font-size: 12px !important;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.col1-br-tbl2{
  width: 64px;
  position: relative;
  box-shadow: 0px 1px 3px #cccccc;
  border-radius: 4px;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  left: -35px;
  font-family: 'Latam Sans Bold';
  display: flex;
  justify-content: center;
  height: 24px;
  place-items: end;
  align-items: center;
  bottom: 421px;
}

.row-mando-cabina-tbl2{
  display: grid  ;
  margin-top: -2px;
}

.mando-box-tbl2-00000300{
  padding: 11px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  justify-content: center;
  width: 108px;
  position: relative;
  bottom: 7px;
  margin-right: -2px;
  bottom: 77px;
  right: 34px;
}
.cabina-box-tbl2-00000300{
  padding: 11px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 107px;
  position: relative;
  margin-right: -2px;
  bottom: 118px;
  left: 104px;
}
.mando-box-tbl2-03000600{
  padding: 11px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 107px;
  position: relative;
  margin-right: -2px;
  bottom: 159px;
  left: 241px;
}
.cabina-box-tbl2-03000600{
  padding: 11px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 108px;
  position: relative;
  margin-right: -2px;
  bottom: 200px;
  left: 378px;
}
.mando-box-tbl2-06000900{
  padding: 11px;
    border-radius: 8px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    display: inline-block;
    font-weight: 900;
    font-family: "Latam Sans Bold";
    font-size: 14px;
    color: #000000;
    background-color: #F6F6F8;
    border: 1px solid #cccccc;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 107px;
    position: relative;
    margin-right: -2px;
    bottom: 241px;
    left: 516px;
}
.cabina-box-tbl2-06000900{
  padding: 11px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 107px;
  position: relative;
  margin-right: -2px;
  bottom: 282px;
  left: 653px;
}
.mando-box-tbl2-09001200{
  padding: 11px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 107px;
  position: relative;
  margin-right: -2px;
  bottom: 323px;
  left: 790px;
}
.cabina-box-tbl2-09001200{
  padding: 11px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 108px;
  position: relative;
  margin-right: -2px;
  bottom: 364px;
  left: 927px;
}

.bra-tbl2-12pm {
  font-size: 14px !important;
  position: relative;
  right: 62px;
}

.bra-tbl2-15pm {
  font-size: 14px !important;
  position: relative;
  right: 31px;
}

.bra-tbl2-18pm {
  flex-grow: 12;
  font-size: 14px !important;
  position: relative;
  right: -20px;
}

.bra-tbl2-21pm {
  flex-grow: 7;
  font-size: 14px !important;
  position: relative;
  left: 61px;
}


.doble-br-tbl2 {
  height: 25px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  padding: 0px;
  position: relative;
  bottom: 358px;
  right: 31px;
}

 .img-ato-casa-tbl2{
  width: 15px;
    height: 15px;
    top: 2px;
    position: relative;
    left: 6px;
 }

.rojo-br-tbl2 {
  background-color: #ED1650;
  padding: 0px;
}

.amarillo-br-tbl2 {
  background-color: #FFC700;
  padding: 0px;
}


.input-br-tbl2 {
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
}

.main-br-tbl2 {
  width: 100%;
  display: grid;
}

.show-br-tbl2 {
  display: block;
}

.hidden-br-tbl2 {
  display: none;
}
.scheduler-tbl2{
  background: rgba(169, 172, 175, 0.267);
  color: #31363F;
  height: 34px;
  border: 1px solid rgb(111, 113, 116) !important;
  width: 22%;

}
.col-br-tbl2{
  width: 10%;
}

/*colores 00-03*/
.a-ato-mando-00000300-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: -2px;
  bottom: 1px;
  display: flex;

}
.a-casa-mando-00000300-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: 31px;
  bottom: 23px;
  display: flex;
}
.r-ato-mando-00000300-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;  
  box-sizing: border-box;
  position: relative;
  left: 66px;
  bottom: 45px;
  display: flex;
}
.r-casa-mando-00000300-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: 99px;
  bottom: 67px;
  display: flex;
}
.a-ato-cabina-00000300-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 88px;
  bottom: 1px;
  display: flex;
}
.a-casa-cabina-00000300-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 55px;
  bottom: 23px;
  display: flex;
}
.r-ato-cabina-00000300-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 20px;
  bottom: 45px;
  display: flex;

}
.r-casa-cabina-00000300-tbl2 {
  border-radius: 5px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    width: 30px;
    height: 22px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    position: relative;
    right: -13px;
    bottom: 67px;
    display: flex;
}

/*colores 03-06*/
.a-ato-mando-03000600-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 2px;
  bottom: 1px;
  display: flex;
}
.a-casa-mando-03000600-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -31px;
  bottom: 23px;
  display: flex;
}
.r-ato-mando-03000600-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -67px;
  bottom: 45px;
  display: flex;
}
.r-casa-mando-03000600-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -99px;
  bottom: 67px;
  display: flex;
}
.a-ato-cabina-03000600-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 3px;
  bottom: 1px;
  display: flex;
}
.a-casa-cabina-03000600-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -30px;
  bottom: 23px;
  display: flex;
}
.r-ato-cabina-03000600-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -66px;
  bottom: 45px;
  display: flex;
}
.r-casa-cabina-03000600-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -98px;
  bottom: 67px;
  display: flex;
}

/*colores 06-09*/
.a-ato-mando-06000900-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 2px;
  bottom: 1px;
  display: flex;
}
.a-casa-mando-06000900-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -31px;
  bottom: 23px;
  display: flex;
}
.r-ato-mando-06000900-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -67px;
  bottom: 45px;
  display: flex;
}
.r-casa-mando-06000900-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -99px;
  bottom: 67px;
  display: flex;
}
.a-ato-cabina-06000900-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 3px;
  bottom: 1px;
  display: flex;
}
.a-casa-cabina-06000900-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -30px;
  bottom: 23px;
  display: flex;
}
.r-ato-cabina-06000900-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -66px;
  bottom: 45px;
  display: flex;
}
.r-casa-cabina-06000900-tbl2 {
  border-radius: 5px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    width: 30px;
    height: 22px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    position: relative;
    right: -98px;
    bottom: 67px;
    display: flex;
}

/*colores 09-12*/
.a-ato-mando-09001200-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 2px;
  bottom: 1px;
  display: flex;
}
.a-casa-mando-09001200-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -31px;
  bottom: 23px;
  display: flex;
}
.r-ato-mando-09001200-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -67px;
  bottom: 45px;
  display: flex;
}
.r-casa-mando-09001200-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -99px;
  bottom: 67px;
  display: flex;
}
.a-ato-cabina-09001200-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: 3px;
  bottom: 1px;
  display: flex;
}
.a-casa-cabina-09001200-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -30px;
  bottom: 23px;
  display: flex;
}
.r-ato-cabina-09001200-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -66px;
  bottom: 45px;
  display: flex;
}
.r-casa-cabina-09001200-tbl2 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  right: -98px;
  bottom: 67px;
  display: flex;
}

/*00-03 mando*/
.a-ato-mando-tbl2{
  width: 30px !important;
  height: 27px !important;
  position: relative;
  right: 2px;
  top: -66px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.input-a-ato-mando-tbl2{
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  background-color: #f6f6f8;
  font-weight: 600;
}
.a-casa-mando-tbl2{
  width: 30px !important;
  height: 27px !important;
  position: relative;
  right: 3px;
  top: -66px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.input-a-casa-mando-tbl2{
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  background-color: #f6f6f8;
  font-weight: 600;
}
.r-ato-mando-tbl2{
  width: 30px !important;
  height: 27px !important;
  position: relative;
  right: -2px;
  top: -66px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.input-r-ato-mando-tbl2{
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  background-color: #f6f6f8;
  font-weight: 600;
}
.r-casa-mando-tbl2{
  width: 30px !important;
  height: 27px !important;
  position: relative;
  right: -1px;
  top: -66px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.input-r-casa-mando-tbl2{
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  background-color: #f6f6f8;
  font-weight: 600;
}
/*00-03 cabina*/
.a-ato-cabina-tbl2{
  width: 30px !important;
  height: 27px !important;
  position: relative;
  right: -6px;
  top: -66px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;

  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.input-a-ato-cabina-tbl2{
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  background-color: #f6f6f8;
  font-weight: 600;
}
.a-casa-cabina-tbl2{
  width: 30px !important;
  height: 27px !important;
  position: relative;
  right: -5px;
  top: -66px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.input-a-casa-cabina-tbl2{
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  background-color: #f6f6f8;
  font-weight: 600;
}
.r-ato-cabina-tbl2{
  width: 30px !important;
  height: 27px !important;
  position: relative;
  right: -10px;
  top: -66px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.input-r-ato-cabina-tbl2{
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  background-color: #f6f6f8;
  font-weight: 600;
}
.r-casa-cabina-tbl2{
  width: 32px !important;
  height: 27px !important;
  position: relative;
  right: -9px;
  top: -66px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.input-r-casa-cabina-tbl2{
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  background-color: #f6f6f8;
  font-weight: 600;
}

.bold-text-bra {
  font-weight: bolder;
  color: #020202;
  font-size: initial;
  font-family: "Latam Sans Regular";
  font-size: 14px;
  position: relative;
  bottom: 29px;
  left: 260px;
}

.cf-buttons-bra {
  display: flex;
  position: relative;
  bottom: 664px;
  right: 264px;
}
.w-tab.cs {
  font-family: "Latam Sans Regular";
  color: #1A86F4;
  font-size: 2vh;
}
