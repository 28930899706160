.theader-sl {
  background-color: rgba(169, 172, 175, 0.267);
  color: #31363F;
  width: 68px;
}

.table-sl {

  font-family: "Latam Sans Regular Italic";
  font-size: 14px;
  font-weight: lighter !important;
  font-style: italic;
  width: 1200px;
  color: #31363F;
  padding: 0px;

}

.doble-sl {
  width: 68px !important;
  height: 34px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(111, 113, 116) !important;
  border-collapse: collapse;
  padding: 0px;
}

.simple-sl {
  height: 34px;
  width: 34px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  padding: 0px;

  border-collapse: collapse;
}

.rojo-sl {
  background-color: #EB5757;
  padding: 0px;
}

.amarillo-sl {
  background-color: #FFC700;
  padding: 0px;
}

.borde-sl {
  border-left: 1px solid rgb(111, 113, 116);
}

.col1-sl {
  width: 68px;
}

.input-sl {
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
}

.table-main-sl {
  width: 100%;
  overflow-x: scroll;
}


.show {
  display: block;
}

.hidden {
  display: none;
}