.new-summary-limops {
  height: 38vh;
  width: 100%;
  background: #363636;
  margin: 0.8vh 0;
  border-radius: 4px;
}

.new-summary-header {
  display: flex;
  justify-content: space-between;
  width: 98%;
  height: 8vh;
  margin-left: 1vh;
  margin-right: 1.5vh;
}

.generic-title {
  width: 60%;
}

.new-summary-limops-main {
  margin: 0 auto;
  width: 100%;
  height: 14.5vh;
}

.sl-counter-grp {
  display: inline-flex;
  padding-top: 1vh;
}

.sl-counter-grp-b {
  display: inline-flex;
  margin: 0;
}

.sl-carousell-dots {
  display: block;
  text-align: center;
  width: 100%;
  height: 4.1vh;
  margin-top: 12vh;
}

.summary-limops .sl-dot {
  cursor: pointer;
  height: 1.6vh;
  width: 1.6vh;
  margin: 0 0.4vh;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.summary-limops .sl-divisor {
  display: inline-block;
  position: relative;
  top: 37.3vh;
  left: 8.7%;
  width: 73vh;
  height: 0.4vh;
  background-color: #000000;
  transform: rotate(90deg);
  opacity: 80%;
  margin-right: 5%;
}

.summary-limops-row {
  display: inline-flex;
  width: 100%;
  color: white;
  font-family: "Latam Sans Bold";
  text-align: center;
  font-size: 2vh;
}

.summary-limops-tittle {
  display: inline-flex;
  font-family: "Latam Sans Bold";
  font-size: 2.3vh;
  width: 100%;
  color: white;
  text-align: center;
}

.crew-summary-limops-tittle {
  display: inline-flex;
  font-family: "Latam Sans Bold";
  font-size: 2.3vh;
  width: 100%;
  color: white;
  text-align: center;
}

.new-summary-limops-tittle {
  display: inline-flex;
  font-family: "Latam Sans Bold";
  font-size: 2.3vh;
  width: 100%;
  color: white;
  text-align: center;
}

.summary-limops-colx2 {
  width: 21%;
  padding: 0.7vh;
}

.summary-limops-oper-type {
  width: 16%;
  padding: 0.7vh;
  background: #10191d;
  border-radius: 7px;
}

.summary-limops-oper-ac {
  width: 12%;
  padding: 0.7vh;
  margin-top: auto;
  margin-bottom: auto;
  background: #10191d;
  border-radius: 7px;
  border-right: 0.9vh;
}

.summary-limops-col {
  width: 10.8%;
  margin: 0 2.5px;
}

.summary-limops-col-ac {
  width: 17%;
  padding: 0.5vh;
  margin-top: auto;
  margin-bottom: auto;
}

.summary-limops-sep-oper {
  width: 1%;
}

.franja-color {
  background: #eb5757;
  width: 3%;
}

.summary-limops-flight {
  width: 12%;
  padding: 0.6vh;
  margin-top: auto;
  margin-bottom: auto;
}

.summary-limops-aircraft {
  width: 15%;
  padding: 0.6vh;
  margin-top: auto;
  margin-bottom: auto;
}

.summary-limops-Bussiness {
  width: 10%;
  background-color: #2100a7;
  border-radius: 1vh;
  padding: 0.6vh;
  height: 5%;

  margin-top: auto;
  margin-bottom: auto;
}

.summary-limops-Impact {
  width: 19%;
  border-radius: 1vh;
  padding: 0.6vh;
}

.summary-limops-row-style {
  width: 100%;
  color: white;
  border-radius: 0.7vh;
  padding-bottom: 0;
  background: #10191d;
}

.new-summary-limops-row-style {
  width: 100%;
  color: white;
  border-radius: 0.7vh;
  padding-bottom: 0;
  background: #10191d;
}

.summary-limops-row-style-tittle {
  width: 100%;
  color: white;
  margin-top: 0.4vh;
  border-radius: 0.4vh;
  padding-bottom: 0.3vh;
  padding-top: 0.3vh;
}

.summary-limops-time {
  font-family: "ZCOOL";
  font-weight: normal;
  font-size: 3vh;
}

.summary-limops-font {
  font-size: 3vh;
}

.summary-limops-separator {
  width: 100%;
  height: 0.1vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
  background-color: white;
}

.summary-limops-sep {
  width: 0.1vh;
  height: auto;
  background-color: white;
}

.summary-limops-cols {
  width: 98%;
  padding: 0 1%;
}

.summary-limops-group {
  display: inline-flex;
  width: 100%;
}

.summary-flight-tittle {
  width: 100%;
  margin-left: 2%;
  margin-right: 0.3%;
  margin-bottom: 0.6vh;
  background-color: #2100a7;
  text-align: center;
  font-size: 1.8vh;
  font-family: "Latam Sans Bold Italic";
  color: white;
  border-radius: 0.4vh;
}

.summary-flight-with-tittle {
  display: block;
  width: 97%;
}

.summary-limops-tittle-Bussiness {
  width: 13%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 2px;
  margin-right: 2px;
}
